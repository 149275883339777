import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CommercialPage = () => (
  <Layout>
    <SEO title="commercial" />
    <h1>Commercial</h1>
    <p>This site is under contstruction.</p>
  </Layout>
)

export default CommercialPage
